export default function spliceString(
    str: string,
    index: number,
    remove: number,
    add?: string,
) {
    if (index < 0) {
        index = Math.max(0, str.length + index);
    }
    return str.slice(0, index) + (add || "") + str.slice(index + remove);
}
