import type { FilterState } from "../../../plugins/filter-manager/interface";
import type { PaginationState } from "../../../plugins/pagination-manager/interface";

import type {
    FilterDrawerExtraData,
    FilterDrawerGroupExtraData,
} from "../../../../components/FilterDrawer/interface";

import type { AccountPayloadWithAudits } from "../../../../services/accounts/payloads/interface";

export type State = {
    payloads: PaginationState<AccountPayloadWithAudits>;
    filters: FilterState<FilterDrawerExtraData, FilterDrawerGroupExtraData>;
};

export const moduleId = "accountPayloads" as const;
