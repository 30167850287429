import type { FC } from "react";

import { memo, useEffect, useRef, useState } from "react";

import { formatPanelId, useTabsContext } from "./helpers";
import type { TabInfo } from "./interface";

import deepEqual from "../../../utils/objects/deepEqual";
import { classes } from "../../../utils/styles/tailwind/v4";

interface TabPanelProps {
    info: TabInfo;
}

const TabPanel = memo<TabPanelProps>(
    function TabPanel({ info: { Component, key, slug, lazy, persist } }) {
        const [mustRender, setMustRender] = useState(!lazy);
        const mustRenderRef = useRef(mustRender);

        const { current } = useTabsContext();

        useEffect(() => {
            if (!lazy) return;
            if (current === key && !mustRenderRef.current) {
                mustRenderRef.current = true;
                setMustRender(true);
            } else if (current !== key && !persist && mustRenderRef.current) {
                mustRenderRef.current = false;
                setMustRender(false);
            }
        }, [current, key, persist, lazy]);

        if (!mustRender || !Component) return null;
        return (
            <div
                id={formatPanelId(slug)}
                role="tabpanel"
                className={`absolute inset-0 -right-18 overflow-x-hidden overflow-y-auto scrollbar-floating ${
                    current === key ? "block" : "hidden"
                }`}>
                <Component />
            </div>
        );
    },
    (prevProps, nextProps) => deepEqual(nextProps, prevProps),
);

interface TabPanelsProps {
    className?: string;
}

const TabPanels: FC<TabPanelsProps> = ({ className }) => {
    const { tabs } = useTabsContext();
    return (
        <div className={classes`relative flex-1 ${className}`}>
            {tabs.map((tab) => (
                <TabPanel
                    key={`${tab.key}${tab.hash ? `/${tab.hash}` : ""}`}
                    info={tab}
                />
            ))}
        </div>
    );
};

export default TabPanels;
