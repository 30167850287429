import type { FC } from "react";

import {
    toastCommonStyle,
    toastIconVariants,
    toastVariants,
} from "./interface";
import type { ToastData } from "./interface";
import { toastIconMap } from "./helpers";

import Icon from "../Icons";

import { classes } from "../../../utils/styles/tailwind/v4";

interface ToastCardProps
    extends Pick<
        ToastData,
        "className" | "description" | "isClosable" | "status" | "title"
    > {
    onClose?: () => void;
}

export const ToastCard: FC<ToastCardProps> = ({
    className,
    description,
    isClosable = true,
    status = "info",
    title,
    onClose,
}) => {
    return (
        <div
            role="status"
            aria-atomic
            className={classes`${toastCommonStyle} ${toastVariants[status]} ${className}`}
            onClick={() => isClosable && onClose?.()}>
            <Icon
                info={toastIconMap[status]}
                className={`w-24 min-w-24 h-24 rounded-full ${toastIconVariants[status]}`}
            />
            <div className="flex-0 space-y-4">
                {title && (
                    <h4 className="mb-0 text-base font-bold text-inherit">
                        {title}
                    </h4>
                )}
                {description && (
                    <p className="mb-0 whitespace-pre-line">{description}</p>
                )}
            </div>
        </div>
    );
};

export default ToastCard;
