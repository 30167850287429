import type { FilterState } from "../../plugins/filter-manager/interface";
import type { PaginationState } from "../../plugins/pagination-manager/interface";

import type { PersistentState } from "../../utils/interface";

import type { SkuCatalogData } from "../../../services/skus/catalog/interface";
import type { SkuTypes } from "../../../services/skus/catalog/interface";
import type {
    GetSkusCatalogOrder,
    GetSkusCatalogOrderBy,
} from "../../../services/skus/catalog/get";

import type { GetSkusParams } from "../../../../services/skus/getAll";
import type { Sku } from "../../../../services/skus/interface";

export interface CategoryInfo {
    AccountName: string;
    IsActive: 0 | 1;
    Name: string;
    label: string;
    TotalSkus: number;
    value: number;
}

export interface FilterExtraData {
    adminOnly?: boolean;
}

export interface FilterLoaderParams {
    isAdmin?: boolean;
}

export interface PaginationParams
    extends Partial<Omit<GetSkusParams, "Page" | "IDTypeSku">> {
    companyId: number;
    companyName: string;
    hasKitsRelated?: boolean;
    IDTypeSku?: number | number[] | SkuTypes[];
    isAdmin: boolean;
    order?: GetSkusCatalogOrder;
    sort?: GetSkusCatalogOrderBy;
}

export type SkuItemComplete = Pick<
    Sku,
    | "BarCode"
    | "Brand"
    | "BuyOrdemQuantity"
    | "Category"
    | "CostSet"
    | "HasMatch"
    | "IDProduct"
    | "IDSku"
    | "IDSkuCompany"
    | "MainImageURL"
    | "MainImageThumbnailURL"
    | "PriceSell"
    | "ProductVariation"
    | "QtyAvailable"
    | "QtyReserved"
    | "QtySafetyStock"
    | "SkuCest"
    | "SkuCstDescription"
    | "SkuName"
    | "SkuNCM"
    | "SkuNCMExTipi"
    | "Tags"
    | "TypeProduct"
    | "TypeProductVariationValue"
    | "TypeStatusSku"
>;
export type SkuItemCatalog = SkuCatalogData;
export type SkuItem = SkuItemComplete | SkuItemCatalog;

export type State = PersistentState<{
    highlights: SkuItemCatalog[] | null;
    products: PaginationState<SkuItem, NonNullable<unknown>, PaginationParams>;
    filters: FilterState<FilterExtraData>;
}>;

export const moduleId = "skus" as const;
