export type TooltipPositions = `${"top" | "bottom" | "left" | "right"}${
    | "-start"
    | "-end"
    | ""}`;

export const tooltipPositions: Record<TooltipPositions, string> = {
    bottom: "origin-top top-full left-1/2 -translate-x-1/2 translate-y-8",
    "bottom-end": "origin-top-right top-full right-0 translate-y-8",
    "bottom-start": "origin-top-left top-full left-0 translate-y-8",
    left: "origin-right top-1/2 right-full -translate-x-8 -translate-y-1/2",
    "left-end": "origin-bottom-right bottom-0 right-full -translate-x-8",
    "left-start": "origin-top-right top-0 right-full -translate-x-8",
    right: "origin-left top-1/2 left-full translate-x-8 -translate-y-1/2",
    "right-end": "origin-bottom-left bottom-0 left-full translate-x-8",
    "right-start": "origin-top-left top-0 left-full translate-x-8",
    top: "origin-bottom bottom-full left-1/2 -translate-x-1/2 -translate-y-8",
    "top-end": "origin-bottom-right bottom-full right-0 -translate-y-8",
    "top-start": "origin-bottom-left bottom-full left-0 -translate-y-8",
};

export const tooltipArrowPosition: Record<TooltipPositions, string> = {
    bottom: "bottom-full left-1/2 -translate-x-1/2 translate-y-1/2",
    "bottom-end": "bottom-full right-0 -translate-x-full translate-y-1/2",
    "bottom-start": "bottom-full left-0 translate-x-full translate-y-1/2",
    left: "top-1/2 left-full -translate-x-1/2 -translate-y-1/2",
    "left-end": "bottom-0 left-full -translate-x-1/2 -translate-y-full",
    "left-start": "top-0 left-full -translate-x-1/2 translate-y-full",
    right: "top-1/2 right-full translate-x-1/2 -translate-y-1/2",
    "right-end": "bottom-0 right-full translate-x-1/2 -translate-y-full",
    "right-start": "top-0 right-full translate-x-1/2 translate-y-full",
    top: "top-full left-1/2 -translate-x-1/2 -translate-y-1/2",
    "top-end": "top-full right-0 -translate-x-full -translate-y-1/2",
    "top-start": "top-full left-0 translate-x-full -translate-y-1/2",
};
