import type { State } from "./interface";

import { createSelectorsCreator } from "../../../../utils";
import { DEFAULT_STATUS } from "../../../../utils/helpers";

export const selectorsSetup = createSelectorsCreator({} as State | undefined)(
    (creator) => ({
        getModuleStatus: creator(
            (state) => [
                !!state,
                state?.status,
                state?.userErrors || [],
                state?.offerInfo || null,
            ],
            (live, status, userErrors, offerInfo) => ({
                live,
                processing: status === DEFAULT_STATUS.PROCESSING,
                failure: status === DEFAULT_STATUS.FAILURE,
                success: status === DEFAULT_STATUS.SUCCESS,
                userErrors,
                offerInfo,
            }),
        ),
        getAccounts: creator(
            (state) => [state?.accounts.list || []],
            (accounts) =>
                accounts
                    .map(({ AccountName }) => AccountName)
                    .sort((a, b) => a.localeCompare(b)),
        ),
        getAccountsStatus: creator(
            (state) => [state?.accounts.status],
            (status) => ({
                hydrated: !!status && status !== DEFAULT_STATUS.PENDING,
                loading: status === DEFAULT_STATUS.LOADING,
            }),
        ),
        getSku: creator(
            (state) => [state?.sku.data || null],
            (sku) => sku,
        ),
        getImages: creator(
            (state) => [state?.sku.images || []],
            (images) => images,
        ),
        getRelatedKits: creator(
            (state) => [state?.sku.relatedKits || []],
            (relatedKits) => relatedKits,
        ),
        getSkuStatus: creator(
            (state) => [
                state?.sku.status,
                state?.sku.data?.IDSku,
                state?.sku.data?.IDStatusSku,
                !!state?.sku.relatedKits.length,
            ],
            (status, currentId, statusId, hasRelatedKits) => ({
                currentId,
                hasRelatedKits,
                hydrated: !!status && status !== DEFAULT_STATUS.PENDING,
                isActive: statusId === 1,
                loading: status === DEFAULT_STATUS.LOADING,
            }),
        ),
        getIntegrations: creator(
            (state) => [state?.integrations.list || []],
            (integrations) =>
                integrations
                    .filter(
                        ({ IDTypeCompanyIntegration }) =>
                            ![1, 16].includes(IDTypeCompanyIntegration),
                    )
                    .map(
                        ({
                            AccountName,
                            CompanyIntegrationName,
                            IDTypeCompanyIntegration,
                            IDCompanyIntegration,
                            IntegrationName,
                        }) => ({
                            AccountName,
                            CompanyIntegrationName,
                            IDTypeCompanyIntegration,
                            IDCompanyIntegration,
                            IntegrationName,
                        }),
                    )
                    .sort((a, b) =>
                        a.CompanyIntegrationName.localeCompare(
                            b.CompanyIntegrationName,
                        ),
                    ),
        ),
        getIntegrationsStatus: creator(
            (state) => [state?.integrations.status],
            (status) => ({
                hydrated: !!status && status !== DEFAULT_STATUS.PENDING,
                loading: status === DEFAULT_STATUS.LOADING,
            }),
        ),
        getIntegrationInstanceData: creator(
            (state, companyIntegrationId?: string | number | null) => [
                state?.integrations.instanceData?.[
                    companyIntegrationId || ""
                ] || null,
            ],
            (instanceData) => instanceData,
        ),
        getIntegrationInstanceDataStatus: creator(
            (state, companyIntegrationId?: string | number | null) => [
                state?.integrations.instanceData?.[companyIntegrationId || ""]
                    ?.status,
            ],
            (status) => ({
                hydrated: !!status && status !== DEFAULT_STATUS.PENDING,
                loading: status === DEFAULT_STATUS.LOADING,
            }),
        ),
        getIntegrationTypeData: creator(
            (state, typeCompanyIntegrationId?: string | number | null) => {
                const typeData =
                    state?.integrations.typeData?.[
                        typeCompanyIntegrationId || ""
                    ];
                return [typeData?.category || null, typeData?.resource || null];
            },
            (category, resource) => ({ category, resource }),
        ),
        getIntegrationSpecifications: creator(
            (state, typeCompanyIntegrationId?: string | number | null) => {
                const specifications =
                    state?.integrations.typeData?.[
                        typeCompanyIntegrationId || ""
                    ]?.specifications;
                return [
                    specifications?.definitions || [],
                    specifications?.records || [],
                ];
            },
            (definitions, records) => ({
                definitions,
                records,
            }),
        ),
        getIntegrationTypeDataStatus: creator(
            (state, typeCompanyIntegrationId?: string | number | null) => {
                const typeData =
                    state?.integrations.typeData?.[
                        typeCompanyIntegrationId || ""
                    ];
                return [
                    typeData?.status,
                    !!typeData?.specifications.definitions.length,
                ];
            },
            (status, hasSpecs) => ({
                hasSpecs,
                hydrated: !!status && status !== DEFAULT_STATUS.PENDING,
                loading: status === DEFAULT_STATUS.LOADING,
            }),
        ),
    }),
);
