import type { AccountPaymentModels } from "../../services/accounts/payloads/interface";

import type { ExposeKeys } from "../../utils/types";

export enum SignUpSteps {
    ACCOUNT,
    PAYMENT,
    SUCCESS,
}

export enum SignUpFormNames {
    ACCOUNT_NAME = "accountName",
    ADDRESS = "address",
    ADDRESS_NUMBER = "addressNumber",
    BIRTHDATE = "birthdate",
    CARD_EXPIRATION_DATE_MONTH = "cardExpirationDateMonth",
    CARD_EXPIRATION_DATE_YEAR = "cardExpirationDateYear",
    CARD_NUMBER = "cardNumber",
    CARD_NAME = "cardName",
    CARD_SECURITY_CODE = "cardSecurityCode",
    CITY = "city",
    COMPLEMENT = "complement",
    DOCUMENT = "document",
    DOCUMENT_TYPE = "documentType",
    EMAIL = "email",
    FINANCIAL_MODEL = "financialModel",
    INSTALLMENT_COUNT = "installment_count",
    NEIGHBORHOOD = "neighborhood",
    PAYMENT_METHOD = "paymentMethod",
    PHONE = "phone",
    PROVINCE = "province",
    USERNAME = "username",
    ZIP = "zip",
}

export enum DocumentTypes {
    CNPJ = "cnpj",
    CPF = "cpf",
}

export interface SignUpFormData {
    [SignUpSteps.ACCOUNT]: ExposeKeys<{
        [SignUpFormNames.BIRTHDATE]?: string;
        [SignUpFormNames.DOCUMENT]: string;
        [SignUpFormNames.DOCUMENT_TYPE]: DocumentTypes;
        [SignUpFormNames.FINANCIAL_MODEL]?: AccountPaymentModels;
        [SignUpFormNames.ACCOUNT_NAME]: string;
        [SignUpFormNames.EMAIL]: string;
        [SignUpFormNames.PHONE]: string;
        [SignUpFormNames.USERNAME]: string;
    }>;
    [SignUpSteps.PAYMENT]: ExposeKeys<{
        [SignUpFormNames.CARD_EXPIRATION_DATE_MONTH]?: string;
        [SignUpFormNames.CARD_EXPIRATION_DATE_YEAR]?: string;
        [SignUpFormNames.CARD_NAME]?: string;
        [SignUpFormNames.CARD_NUMBER]?: string;
        [SignUpFormNames.CARD_SECURITY_CODE]?: string;
        [SignUpFormNames.INSTALLMENT_COUNT]?: number;
        [SignUpFormNames.PAYMENT_METHOD]: string;
        [SignUpFormNames.ADDRESS]?: string;
        [SignUpFormNames.ADDRESS_NUMBER]?: number;
        [SignUpFormNames.CITY]?: string;
        [SignUpFormNames.COMPLEMENT]?: string;
        [SignUpFormNames.NEIGHBORHOOD]?: string;
        [SignUpFormNames.PROVINCE]?: string;
        [SignUpFormNames.ZIP]?: string;
    }>;
}
