import type { FC, ReactNode } from "react";

import { useMemo, useState } from "react";

import { tooltipPositions, tooltipArrowPosition } from "./interface";
import type { TooltipPositions } from "./interface";

import { classes } from "../../../utils/styles/tailwind/v4";

interface TooltipProps {
    arrowStyle?: string;
    children?: ReactNode;
    className?: string;
    containerStyle?: string;
    label: ReactNode;
    isOpen?: boolean;
    isDisabled?: boolean;
    hideArrow?: boolean;
    position?: TooltipPositions;
}

const Tooltip: FC<TooltipProps> = ({
    label,
    position = "bottom-start",
    isOpen: _isOpen,
    isDisabled,
    hideArrow,
    arrowStyle: _arrowStyle,
    containerStyle = "",
    className,
    children,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleHoverStart = () => !isDisabled && setIsOpen(true);
    const handleHoverEnd = () => !isDisabled && setIsOpen(false);

    const arrowStyle = useMemo(
        () =>
            classes`absolute -z-10 w-8 h-8 bg-slate-200 rotate-45 ${tooltipArrowPosition[position]} ${_arrowStyle}`,
        [position, _arrowStyle],
    );

    const currentStyle = useMemo(
        () =>
            classes`absolute ${
                tooltipPositions[position]
            } bg-slate-200 rounded-2 px-8 py-2 transition-all duration-100 ${
                _isOpen ?? isOpen
                    ? "scale-100 opacity-100"
                    : "scale-0 opacity-0 pointer-events-none touch-none"
            } ${className}`,
        [isOpen, _isOpen, position, className],
    );

    return (
        <div
            className={`relative ${containerStyle}`}
            onMouseEnter={handleHoverStart}
            onMouseLeave={handleHoverEnd}>
            {children}
            <div role="tooltip" className={currentStyle}>
                {label}
                {!hideArrow && <div className={arrowStyle} />}
            </div>
        </div>
    );
};

export default Tooltip;
