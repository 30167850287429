import type { ChangeEventHandler, FC } from "react";

import { useMemo, useState, useTransition } from "react";

import { SignUpFormNames, SignUpSteps } from "../../interface";
import type { SignUpFormData } from "../../interface";

import FormControl, {
    FormLabel,
    FormErrorMessage,
} from "../../../../components/@basic/Forms/FormControl";
import Input from "../../../../components/@basic/Forms/Input";
import Select from "../../../../components/@basic/Forms/Select";
import { InfoCircleIcon } from "../../../../components/@basic/Icons/Mocks";
import Tooltip from "../../../../components/@basic/Tooltip";

import type { PaymentMethod } from "../../../../services/miscellaneous/getPaymentMethods";

import type { ErrorRelation } from "../../../../utils/forms/validation";

import formatMoney from "../../../../utils/formatters/formatMoney";
import { chainEventHandlers } from "../../../../utils/inputs/events";
import {
    creditCardMask,
    creditCardMaskHandler,
} from "../../../../utils/inputs/masks/creditCard";
import {
    onlyNumbersMask,
    onlyNumbersMaskHandler,
} from "../../../../utils/inputs/masks/onlyNumbers";
import { creditCardPattern } from "../../../../utils/inputs/patterns/creditCard";
import { numbersPattern } from "../../../../utils/inputs/patterns/intervals";
import spliceString from "../../../../utils/strings/splice";

function parseCardNumber(str?: string) {
    const digits = str?.match(/\d/g)?.length || 0;
    const pattern = digits === 15 ? "XXXX XXXXXX XXXXX" : "XXXX XXXX XXXX XXXX";
    return spliceString(pattern, 0, str?.length || 0, str);
}

interface CardContent {
    cvv: string;
    name: string;
    number: string;
    month: string;
    year: string;
}

interface CreditCardFormProps {
    data?: SignUpFormData[SignUpSteps.PAYMENT];
    errors: ErrorRelation<SignUpFormData[SignUpSteps.PAYMENT]>;
    isDisabled?: boolean;
    method: PaymentMethod;
    totalPrice?: number;
}

const CreditCardForm: FC<CreditCardFormProps> = ({
    data,
    errors,
    isDisabled,
    method: { maxInstallments },
    totalPrice,
}) => {
    const [cardContent, setCardContent] = useState<CardContent>({
        cvv: (data?.cardSecurityCode || "").padEnd(3, "*"),
        month: (data?.cardExpirationDateMonth || "").padStart(2, "*"),
        name: data?.cardName || "***** * * ******",
        number: parseCardNumber(data?.cardNumber),
        year: (data?.cardExpirationDateYear || "").slice(2).padStart(2, "*"),
    });
    const [showCardBack, setShowCardBack] = useState(false);

    const [, startTransition] = useTransition();

    const handleChange: ChangeEventHandler<
        HTMLInputElement | HTMLSelectElement
    > = (event) => {
        const value = event.target.value;
        switch (event.target.name) {
            case SignUpFormNames.CARD_NUMBER:
                setCardContent((prev) => ({
                    ...prev,
                    number: parseCardNumber(value),
                }));
                break;
            case SignUpFormNames.CARD_NAME:
                setCardContent((prev) => ({
                    ...prev,
                    name: value || "***** * * ******",
                }));
                break;
            case SignUpFormNames.CARD_EXPIRATION_DATE_MONTH:
                setCardContent((prev) => ({
                    ...prev,
                    month: (value || "").padStart(2, "*"),
                }));
                break;
            case SignUpFormNames.CARD_EXPIRATION_DATE_YEAR:
                setCardContent((prev) => ({
                    ...prev,
                    year: (value || "").slice(2).padStart(2, "*"),
                }));
                break;
            case SignUpFormNames.CARD_SECURITY_CODE:
                setCardContent((prev) => ({
                    ...prev,
                    cvv: (value || "").padEnd(3, "*"),
                }));
                break;
            default:
                break;
        }
    };

    const handleFocus = () => startTransition(() => setShowCardBack(true));
    const handleBlur = () => startTransition(() => setShowCardBack(false));

    const installmentOptions = useMemo(
        () =>
            maxInstallments && totalPrice
                ? new Array(maxInstallments || 1).fill(null).map((_, idx) => ({
                      value: idx + 1,
                      label: `${idx + 1}x de ${formatMoney(
                          totalPrice / (idx + 1),
                      )}`,
                  }))
                : [],
        [maxInstallments, totalPrice],
    );

    const monthOptions = useMemo(
        () =>
            new Array(12)
                .fill(null)
                .map((_, idx) => `${idx + 1}`.padStart(2, "0")),
        [],
    );
    const yearOptions = useMemo(() => {
        const currentYear = new Date().getFullYear();
        return new Array(30).fill(null).map((_, idx) => currentYear + idx);
    }, []);

    const {
        cardExpirationDateMonth,
        cardExpirationDateYear,
        cardName,
        cardNumber,
        cardSecurityCode,
        installment_count,
    } = data || {};

    return (
        <div className="grid grid-cols-1 sm:grid-cols-[1fr_256px] gap-24 md:gap-32">
            <div className="grid grid-cols-6 gap-x-16 gap-y-16 md:gap-y-24">
                <FormControl
                    className="col-span-6"
                    isRequired
                    isDisabled={isDisabled}
                    isInvalid={!!errors.cardNumber}>
                    <FormLabel>Número do cartão</FormLabel>
                    <Input
                        type="text"
                        name={SignUpFormNames.CARD_NUMBER}
                        data-label="Número do cartão"
                        data-parser="onlyNumbers"
                        placeholder="Digite o número do cartão"
                        pattern={creditCardPattern}
                        defaultValue={creditCardMask(cardNumber || "")}
                        onChange={chainEventHandlers(
                            creditCardMaskHandler,
                            handleChange,
                        )}
                    />
                    <FormErrorMessage>
                        {errors.cardNumber?.[0]}
                    </FormErrorMessage>
                </FormControl>
                <FormControl
                    className="col-span-6"
                    isRequired
                    isDisabled={isDisabled}
                    isInvalid={!!errors.cardName}>
                    <FormLabel>Nome impresso no cartão</FormLabel>
                    <Input
                        type="text"
                        name={SignUpFormNames.CARD_NAME}
                        data-label="Nome impresso no cartão"
                        data-parser="uppercase"
                        placeholder="Digite o nome"
                        defaultValue={cardName}
                        onChange={handleChange}
                    />
                    <FormErrorMessage>{errors.cardName?.[0]}</FormErrorMessage>
                </FormControl>

                <FormControl
                    className="col-span-3 md:col-span-2"
                    isRequired
                    isDisabled={isDisabled}
                    isInvalid={!!errors.cardExpirationDateMonth}>
                    <FormLabel>Mês</FormLabel>
                    <Select
                        name={SignUpFormNames.CARD_EXPIRATION_DATE_MONTH}
                        data-label="Mês de validate"
                        defaultValue={cardExpirationDateMonth}
                        onChange={handleChange}>
                        <option key="default" value="">
                            MM
                        </option>
                        {monthOptions.map((month) => (
                            <option key={month} value={month}>
                                {month}
                            </option>
                        ))}
                    </Select>
                    <FormErrorMessage>
                        {errors.cardExpirationDateMonth?.[0]}
                    </FormErrorMessage>
                </FormControl>
                <FormControl
                    className="col-span-3 md:col-span-2"
                    isRequired
                    isDisabled={isDisabled}
                    isInvalid={!!errors.cardExpirationDateYear}>
                    <FormLabel>Ano</FormLabel>
                    <Select
                        name={SignUpFormNames.CARD_EXPIRATION_DATE_YEAR}
                        data-label="Ano de validate"
                        defaultValue={cardExpirationDateYear}
                        onChange={handleChange}>
                        <option key="default" value="">
                            AA
                        </option>
                        {yearOptions.map((year) => (
                            <option key={year} value={year}>
                                {year - 2000}
                            </option>
                        ))}
                    </Select>
                    <FormErrorMessage>
                        {errors.cardExpirationDateYear?.[0]}
                    </FormErrorMessage>
                </FormControl>
                <FormControl
                    className="col-span-6 md:col-span-2"
                    isRequired
                    isDisabled={isDisabled}
                    isInvalid={!!errors.cardSecurityCode}>
                    <div className="flex justify-between">
                        <FormLabel>CVV</FormLabel>
                        <Tooltip
                            position="bottom-end"
                            className="w-max max-w-256"
                            label="CVV: sequência de três (3) números que representa o código de segurança do cartão. Geralmente é encontrado no verso do cartão.">
                            <InfoCircleIcon className="w-14 h-20" />
                        </Tooltip>
                    </div>
                    <Input
                        type="text"
                        name={SignUpFormNames.CARD_SECURITY_CODE}
                        data-label="CVV"
                        placeholder="CVV"
                        pattern={`${numbersPattern}+`}
                        minLength={3}
                        maxLength={4}
                        defaultValue={onlyNumbersMask(cardSecurityCode || "")}
                        onChange={chainEventHandlers(
                            onlyNumbersMaskHandler,
                            handleChange,
                        )}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                    />
                    <FormErrorMessage>
                        {errors.cardSecurityCode?.[0]}
                    </FormErrorMessage>
                </FormControl>

                {maxInstallments && maxInstallments > 1 && (
                    <FormControl
                        className="col-span-6"
                        isRequired
                        isDisabled={isDisabled}
                        isInvalid={!!errors.installment_count}>
                        <FormLabel>Nº de parcelas</FormLabel>
                        <Select
                            name={SignUpFormNames.INSTALLMENT_COUNT}
                            data-label="Nº de parcelas"
                            data-parser="toNumber"
                            defaultValue={installment_count}>
                            {installmentOptions.map(({ label, value }, idx) => (
                                <option key={idx} value={value}>
                                    {label}
                                </option>
                            ))}
                        </Select>
                        <FormErrorMessage>
                            {errors.installment_count?.[0]}
                        </FormErrorMessage>
                    </FormControl>
                )}
            </div>

            <div
                className="text-gray-400"
                style={{
                    perspective: "1000px",
                    transformStyle: "preserve-3d",
                }}>
                <div
                    className="aspect-[85/53] w-full max-w-256 mx-auto mt-28 transition-all duration-500"
                    style={{
                        transformStyle: "preserve-3d",
                        transform: showCardBack ? "rotateY(180deg)" : "",
                    }}>
                    <div
                        className="flex flex-col absolute inset-x-0 top-0 min-h-full bg-white border-2 border-gray-300 rounded-8 p-24 pt-32"
                        style={{ backfaceVisibility: "hidden" }}>
                        <div className="bg-gray-200 aspect-[11/8] w-1/5 rounded-4" />
                        <p className="mt-8 mb-0">{cardContent.number}</p>
                        <div className="flex-1 flex justify-between items-end gap-4 mt-4">
                            <p className="mb-0 text-sm uppercase break-all">
                                {cardContent.name}
                            </p>
                            <p className="mb-0">
                                {cardContent.month}/{cardContent.year}
                            </p>
                        </div>
                    </div>
                    <div
                        className="flex flex-col absolute inset-0 bg-white border-2 border-gray-300 rounded-8 pt-32"
                        style={{
                            backfaceVisibility: "hidden",
                            transform: "rotateY(180deg)",
                        }}>
                        <div className="bg-gray-200 w-full h-40" />
                        <p className="mb-0 mt-8 ml-auto mr-24 text-sm">
                            {cardContent.cvv}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreditCardForm;
