import type { FC } from "react";

import {
    Fragment,
    useCallback,
    useEffect,
    useState,
    useMemo,
    useRef,
} from "react";
import { useSearchParams } from "react-router-dom";

import { mapBreadcrumbs, mapSteps, SignUpContext } from "./helpers";
import { DocumentTypes, SignUpSteps } from "./interface";
import type { SignUpFormData } from "./interface";

import AccountInfoStep from "./Steps/AccountInfo";
import PaymentInfoStep from "./Steps/PaymentInfo";
import SuccessStep from "./Steps/Success";

import { ChevronRightIcon } from "../../components/@basic/Icons/Mocks";
import { useToast } from "../../components/@basic/Toast";

import { LOCAL_STORAGE_NAMES } from "../../constants/localStorage";

import useLocalStorage from "../../hooks/storages/useLocalStorage";

import DoladoLoginLayout from "../../layouts/DoladoLogin";

import createAccountPayload from "../../services/accounts/payloads/create";
import type { CreateAccountPayloadPayload } from "../../services/accounts/payloads/create";
import getPaymentMethods, {
    PaymentMethodTypes,
} from "../../services/miscellaneous/getPaymentMethods";
import type { PaymentMethod } from "../../services/miscellaneous/getPaymentMethods";

import { getConsultant } from "../../services/supabase/consultants";
import type { ConsultantData } from "../../services/supabase/consultants";

import encrypt from "../../utils/cryptography/encrypt";
import slugify from "../../utils/formatters/slugify";

const SignUpPage: FC = () => {
    const [search] = useSearchParams();
    const consultantId =
        search.get("id") || process.env.REACT_APP_CONSULTANT_FALLBACK || "";

    const { getItem, removeItems, setItem } = useLocalStorage();

    const dataRef = useRef<Partial<SignUpFormData>>({});
    const [currentStep, setCurrentStep] = useState<SignUpSteps | null>(null);
    const currentStepRef = useRef(currentStep);

    const [consultantData, setConsultantData] = useState<ConsultantData | null>(
        null,
    );
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [payloadInfo, setPayloadInfo] =
        useState<SignUpContext["payloadInfo"]>(null);
    const hydrated = useRef(false);

    const toast = useToast();

    useEffect(() => {
        if (hydrated.current) return;
        hydrated.current = true;
        (async () => {
            setLoading(true);

            const info = getItem(LOCAL_STORAGE_NAMES.ACCOUNT_PAYLOAD_INFO);
            if (info) {
                const now = Date.now();
                const createdAt = new Date(info.created_at);

                let data: SignUpContext["payloadInfo"] = null;
                try {
                    data = JSON.parse(
                        Buffer.from(info.data, "base64").toString(),
                    );
                } catch {
                    //
                }

                const expirationDate =
                    data?.payment.qrCode?.expirationDate &&
                    new Date(data.payment.qrCode.expirationDate);

                if (
                    (expirationDate && expirationDate.getTime() < now) ||
                    (!expirationDate &&
                        now >= createdAt.getTime() + 14_400_000) ||
                    !data
                ) {
                    removeItems([LOCAL_STORAGE_NAMES.ACCOUNT_PAYLOAD_INFO]);
                } else {
                    setPayloadInfo(data);
                    currentStepRef.current = SignUpSteps.SUCCESS;
                    setCurrentStep(SignUpSteps.SUCCESS);
                    setLoading(false);
                    return;
                }
            }

            const promises = [
                getPaymentMethods(),
                consultantId
                    ? getConsultant(consultantId).then(
                          (res) =>
                              res ||
                              getConsultant(
                                  process.env.REACT_APP_CONSULTANT_FALLBACK ||
                                      "",
                              ),
                      )
                    : null,
            ] as const;

            const [methods, consultant] = await Promise.all(promises);
            setPaymentMethods(methods);
            setConsultantData(consultant);
            currentStepRef.current = SignUpSteps.ACCOUNT;
            setCurrentStep(SignUpSteps.ACCOUNT);

            setLoading(false);
        })();
    }, [consultantId, getItem, removeItems]);

    const updateData = useCallback<SignUpContext["updateData"]>(
        (step, newData) => {
            const temp = { ...dataRef.current };
            temp[step] = { ...temp[step], ...newData };
            dataRef.current = temp;
        },
        [],
    );

    const goToNextStep = useCallback<SignUpContext["goToNextStep"]>(() => {
        const current = currentStepRef.current;
        if (
            current === null ||
            [SignUpSteps.SUCCESS].includes(current) ||
            !dataRef.current[current as keyof SignUpFormData]
        )
            return;

        const nextStep = mapSteps[current][1];
        if (nextStep) {
            currentStepRef.current = nextStep;
            setCurrentStep(nextStep);
        }
    }, []);

    const goToPrevStep = useCallback<SignUpContext["goToNextStep"]>(() => {
        const current = currentStepRef.current;
        if (current === null) return;
        const prevStep = mapSteps[current][0];
        if (prevStep) {
            currentStepRef.current = prevStep;
            setCurrentStep(prevStep);
        }
    }, []);

    const submit = useCallback<SignUpContext["submit"]>(async () => {
        setProcessing(true);

        const current = dataRef.current;

        if (!current[SignUpSteps.ACCOUNT]) {
            toast.dispatch({
                status: "warning",
                title: "Dados de conta ausentes",
                duration: 5000,
            });
            setProcessing(false);
            return;
        }

        if (!current[SignUpSteps.PAYMENT]) {
            toast.dispatch({
                status: "warning",
                title: "Dados de pagamento ausentes",
                duration: 5000,
            });
            setProcessing(false);
            return;
        }

        if (!consultantData) return;

        const accountData = current[SignUpSteps.ACCOUNT];
        const paymentData = current[SignUpSteps.PAYMENT];

        const paymentMethod = paymentMethods.find(
            ({ _id }) => _id === paymentData.paymentMethod,
        );

        if (!paymentMethod) {
            toast.dispatch({
                status: "error",
                title: "Forma de pagamento não selecionada",
                duration: 5000,
            });
            return;
        }

        const payload: CreateAccountPayloadPayload = {
            accountName: slugify(accountData.accountName.trim()),
            consultantId: consultantData.id,
            document: accountData.document,
            payment: {
                cardToken: "",
                installments: paymentData.installment_count || 1,
                paymentMethod: paymentMethod._id,
                value: consultantData.subscription_value,
            },
            phone: accountData.phone,
            userEmail: accountData.email,
            userName: accountData.username,
        };

        if (accountData.documentType === DocumentTypes.CPF) {
            const match = accountData.birthdate?.match(
                /(\d{4})-(\d{2})-(\d{2})/,
            );
            if (match) {
                payload.birthDate = `${match[3]}${match[2]}${match[1]}`;
            }
        } else {
            payload.tax = accountData.financialModel;
        }

        if (paymentMethod.asaasType === PaymentMethodTypes.CREDIT_CARD) {
            const {
                cardExpirationDateMonth,
                cardExpirationDateYear,
                cardName,
                cardNumber,
                cardSecurityCode,
            } = paymentData;

            const creditCardPayload = {
                holderName: cardName || "",
                cvv: cardSecurityCode || "",
                expiryMonth: cardExpirationDateMonth || "",
                expiryYear: cardExpirationDateYear || "",
                number: cardNumber,
            };

            const encrypted = await encrypt(JSON.stringify(creditCardPayload));

            payload.payment.cardToken = encrypted;

            payload.billingAddress = {
                ...(paymentData.complement && {
                    complement: paymentData.complement,
                }),
                number: `${paymentData.addressNumber || ""}`,
                zipCode: paymentData.zip || "",
            };
        }

        const response = await createAccountPayload(payload);

        if (!response.__success) {
            console.log(response.message);
            toast.dispatch({
                status: "error",
                title: response.message,
                duration: 5000,
            });
        } else {
            const info = {
                _id: response.data._id,
                accountName: response.data.accountName,
                payment: {
                    qrCode: response.data.payment?.qrCode,
                    value: response.data.payment?.value,
                },
            } satisfies SignUpContext["payloadInfo"];

            const encrypted = Buffer.from(JSON.stringify(info)).toString(
                "base64",
            );
            setItem(LOCAL_STORAGE_NAMES.ACCOUNT_PAYLOAD_INFO, {
                data: encrypted,
                created_at: new Date().toISOString(),
            });

            setPayloadInfo(info);
            currentStepRef.current = SignUpSteps.SUCCESS;
            setCurrentStep(SignUpSteps.SUCCESS);
        }

        setProcessing(false);
    }, [toast, paymentMethods, consultantData, setItem]);

    const renderBreadCrumbs = () => {
        const enabled = currentStep !== null;
        const canGoBack =
            currentStep !== null && mapSteps[currentStep][0] !== null;

        const setStep = (step: number) => () => {
            if (!enabled || step >= currentStep || !canGoBack || loading)
                return;
            currentStepRef.current = step;
            setCurrentStep(step);
        };

        return (
            <div className="flex justify-evenly md:justify-between items-center mb-32 md:mb-40 md:px-24">
                {mapBreadcrumbs.map(([step, label, Icon], idx) => (
                    <Fragment key={step}>
                        {idx > 0 && (
                            <ChevronRightIcon className="w-16 h-16 text-doladoBlue-700" />
                        )}
                        <p
                            className={`mb-0 text-sm font-bold ${
                                enabled && step <= currentStep && !loading
                                    ? "text-doladoBlue-700"
                                    : "text-gray-300"
                            } ${
                                enabled &&
                                step < currentStep &&
                                canGoBack &&
                                !loading
                                    ? "cursor-pointer"
                                    : ""
                            }`}
                            onClick={setStep(step)}>
                            <span className="hidden md:inline">{label}</span>
                            <Icon className="inline-block md:hidden text-inherit" />
                        </p>
                    </Fragment>
                ))}
            </div>
        );
    };

    const state = useMemo<SignUpContext>(
        () => ({
            data: dataRef,
            consultantData,
            paymentMethods,
            currentStep,
            processing,
            loading,
            payloadInfo,
            goToNextStep,
            goToPrevStep,
            submit,
            updateData,
        }),
        [
            currentStep,
            consultantData,
            paymentMethods,
            processing,
            loading,
            payloadInfo,
            goToNextStep,
            goToPrevStep,
            submit,
            updateData,
        ],
    );

    return (
        <DoladoLoginLayout
            styles={{
                container: "md:flex-col lg:flex-row md:max-w-1280",
                infoArea:
                    "lg:max-w-272 max-lg:min-h-144 max-lg:rounded-tr-12 md:rounded-bl-0 lg:rounded-bl-12",
                contentArea:
                    "min-h-360 p-16 py-24 sm:p-24 md:px-32 min-[1100px]:px-80",
            }}>
            <SignUpContext.Provider value={state}>
                {renderBreadCrumbs()}
                <AccountInfoStep />
                <PaymentInfoStep />
                <SuccessStep />
            </SignUpContext.Provider>
        </DoladoLoginLayout>
    );
};

export default SignUpPage;
