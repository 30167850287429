import * as AccountPayloads from "./accounts/payloads/interface";
import * as Dashboard from "./dashboard/interface";
import * as HubOffers from "./hub/offers/interface";
import * as HubOffersCreate from "./hub/offers/create/interface";
import * as SkusCatalogGridLayout from "./skus/catalog/grid-layout/interface";
import * as Skus from "./skus/interface";
import * as SkusSkuSpecificationsReducers from "./skus/sku/specifications/interface";
import * as Wallet from "./wallet/interface";

export const moduleRelation = {
    [AccountPayloads.moduleId]: "/accounts/payloads",
    [Dashboard.moduleId]: "/dashboard",
    [HubOffers.moduleId]: "/hub/offers",
    [HubOffersCreate.moduleId]: "/hub/offers/create",
    [Skus.moduleId]: "/skus",
    [SkusCatalogGridLayout.moduleId]: "/skus/catalog/grid-layout",
    [SkusSkuSpecificationsReducers.moduleId]:
        "/skus/sku/specifications/reducers",
    [Wallet.moduleId]: "/wallet",
};

export interface RootState {
    [AccountPayloads.moduleId]?: AccountPayloads.State;
    [Dashboard.moduleId]?: Dashboard.State;
    [HubOffers.moduleId]?: HubOffers.State;
    [HubOffersCreate.moduleId]?: HubOffersCreate.State;
    [Skus.moduleId]?: Skus.State;
    [SkusCatalogGridLayout.moduleId]?: SkusCatalogGridLayout.State;
    [SkusSkuSpecificationsReducers.moduleId]?: SkusSkuSpecificationsReducers.State;
    [Wallet.moduleId]?: Wallet.State;
}
